.navibar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.companylogo {
    height: 70px;
    width: 80px;
}

.homebtn {
    background-color: white;
    border-color: white;
}

.homebtn:hover {
    background-color: white;
    border-color: white;
}


.aboutbtn {
    background-color: white;
    border-color: white;
}

.aboutbtn:hover {
    background-color: white;
    border-color: white;
}

.servicebtn {
    background-color: white;
    border-color: white;
}

.servicebtn:hover {
    background-color: white;
    border-color: white;
}

.workbtn {
    background-color: white;
    border-color: white;
}

.workbtn:hover {
    background-color: white;
    border-color: white;
}

.contactbtn {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.contactbtn:hover {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.hometext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.hometext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.abouttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.worktext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.contacttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

.contacttext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

/* -------------------- navigation bar complete -------------------- */

.hometoptext {
    font-size: 48px;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hometoptext2 {
    font-size: 48px;
    font-weight: bold;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: #003FBD;
}

.hometoptext3 {
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media (max-width: 480px) {
    .hometoptext {
        font-size: 18px;
        font-weight: bold;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    .hometoptext2 {
        font-size: 12.8px;
        font-weight: bold;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        color: #003FBD;
    }

    .hometoptext3 {
        font-size: 10px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .hometopimage {
        margin-top: 5.5em;
    }

    .homeaboutustext {
        font-size: 10px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }
}

.hometopcard {
    border: 0;
}

.hometopcard2 {
    border: 0;
}

.homeaboutustext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.homeaboutustext2 {
    font-size: 36px;
    position: relative;
    display: inline-block;
}

.homeaboutustext2::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 67%;
    height: 4px;
    background-color: #4caf50;
}

.for {
    font-weight: normal;
}

.about-us-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    max-width: 1200px;
    margin: 0 auto;
}

.image-container {
    flex: 1;
    margin-right: 20px;
    border-radius: 8px;
}

/* .image-container img {
    width: 100%;
    border-radius: 8px;
}

.text-container {
    flex: 1;
}

.text-container h2 {
    font-size: 2rem;
    color: #333;
}

.text-container .highlight {
    color: #2b6cb0;
}

.text-container p {
    margin: 20px 0;
    color: #666;
    line-height: 1.6;
}

.know-more-btn {
    padding: 10px 20px;
    background-color: #2b6cb0;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.know-more-btn:hover {
    background-color: #234e70;
} */

.aboutusimagecard {
    border: 0;
}

.us {
    color: #003FBD;
}

.knowmorebtn {
    background-color: #003FBD;
    border-color: #003FBD;
    border-radius: 8px;
}

.knowmorebtn:hover {
    background-color: #003FBD;
    border-color: #003FBD;
    border-radius: 8px;
}

.knowmorelink {
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
}

.home-our-services {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    position: relative;
    display: inline-block;
}

.home-our-services::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 30%;
    height: 4px;
    background-color: #4caf50;
}

.service {
    color: #003FBD;
}

.home-services-card {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
}

.home-services-card-button {
    background-color: white;
    border-color: white;
}

.home-services-card-button:hover {
    background-color: white;
    border-color: white;
}

.home-services-card-link {
    text-decoration: none;
}



.stats-section {
    background-color: #345095;
    /* Background color */
    color: #fff;
    /* Text color */
    padding: 20px 0;
}

.stat-item {
    margin-bottom: 1px;
}

.stat-value {
    font-size: 48px;
    font-weight: bold;
    color: #62FF6A;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    /* Bright green color */
    position: relative;
    display: inline-block;
}

.stat-value::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #62FF6A;
}

.stat-label {
    margin-top: 10px;
    font-size: 1.2rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}




.home-our-work {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    position: relative;
    display: inline-block;
}

.home-our-work::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 40%;
    height: 4px;
    background-color: #4caf50;
}

.works {
    color: #003FBD;
}

.home-work-btn {
    background-color: #003FBD;
    border-color: #003FBD;
    border-radius: 8px;
}

.home-work-btn:hover {
    background-color: #003FBD;
    border-color: #003FBD;
    border-radius: 8px;
}

.home-work-link {
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
}



.logo-slider-section {
    padding: 40px 0;
    background-color: #f8f9fa;
    /* Light background */
}

.client-logo {
    max-width: 100%;
    max-height: 100px;
    /* Adjust height as needed */
    margin: 0 auto;
}

.logo-slider .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    /* Adjust the height of the carousel items */
}

.logo-slider .carousel-item img {
    max-width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
}

.logo-slider .carousel-item img:hover {
    transform: scale(1.1);
    /* Slightly enlarge the logo on hover */
}

.home-clients {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.home-clients::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 35%;
    height: 4px;
    background-color: #4caf50;
}

.client {
    color: #003FBD;
}

/* .carousel-item-custom {
    flex: 0 0 25%;
    max-width: 25%;
    padding: 5px;
}

.carousel-item-custom img {
    width: 100%;
    height: 100%;
    display: block;
} */

.home-testimonials {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}



.testimonials-section {
    background-color: #f8f9fa;
    /* Light background */
    padding: 60px 0;
}

.testimonial-card {
    border-radius: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.testimonial-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
}

.testimonial-quote {
    font-style: italic;
    font-size: 1.1rem;
    color: #555;
}

.pic {
    background-image: url('./Contactsection.png');
    /* Replace with the actual path to your image */
    background-size: cover;
    /* Ensure the image covers the entire section */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 0;
}

.home-contact-text {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
}

.home-contact-text2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
}

.home-contact-btn {
    background-color: white;
    border-radius: 8px;
    border-color: white;
}

.home-contact-btn:hover {
    background-color: white;
    border-radius: 8px;
    border-color: white;
}

.home-contact-link {
    color: #003FBD;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-decoration: none;
}



.card-hover {
    border: none;
}

.nametitle {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.nametitle2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


/* ---------------------footer----------------------- */

.footer-navi-btn {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-btn:hover {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-link {
    text-decoration: none;
}

/* ------------------Brands logo slider------------------ */

.slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider-track {
    display: flex;
    animation: slide 10s linear infinite;
}

.slider-item {
    flex: 0 0 auto;
    width: 100px;
    /* Base width */
    margin: 0 8em;
}

.imagesize1 {
    height: 12em;
    width: 12.5em;
}

.imagesize2 {
    height: 12em;
    width: 20em;
}

.imagesize3 {
    height: 12em;
    width: 20em;
}

.imagesize4 {
    height: 12em;
    width: 12.5em;
}

@keyframes slide {
    0% {
        transform: translateX(95%);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .slider-item {
        width: 80px;
    }
}

@media (max-width: 768px) {
    .slider-item {
        width: 60px;
    }
}

@media (max-width: 480px) {
    .slider-item {
        width: 50px;
        margin: 0 7em;
    }

    .imagesize1 {
        height: 10em;
        width: 10em;
    }

    .imagesize2 {
        height: 10em;
        width: 18em;
    }

    .imagesize3 {
        height: 10em;
        width: 20em;
    }

    .imagesize4 {
        height: 10em;
        width: 12.5em;
    }


    @keyframes slide {
        0% {
            transform: translateX(30%);
        }

        100% {
            transform: translateX(-100%);
        }
    }
}