.navibar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.companylogo {
    height: 70px;
    width: 80px;
}

.homebtn {
    background-color: white;
    border-color: white;
}

.homebtn:hover {
    background-color: white;
    border-color: white;
}


.aboutbtn {
    background-color: white;
    border-color: white;
}

.aboutbtn:hover {
    background-color: white;
    border-color: white;
}

.servicebtn {
    background-color: white;
    border-color: white;
}

.servicebtn:hover {
    background-color: white;
    border-color: white;
}

.workbtn {
    background-color: white;
    border-color: white;
}

.workbtn:hover {
    background-color: white;
    border-color: white;
}

.contactbtn {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.contactbtn:hover {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.hometext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.hometex2t:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.abouttext2:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.servicetext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.worktext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.contacttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

.contacttext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

/* -------------------- navigation bar complete -------------------- */

.about-pic {
    background-image: url('./Rectangle 10.png');
    /* Replace with the actual path to your image */
    background-size: cover;
    /* Ensure the image covers the entire section */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 0;
    padding: 16.8%;
}

.aboutus {
    font-size: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
}

.about-top-text {
    font-size: 36px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.about-top-text2 {
    color: #003FBD;
}

@media (max-width: 480px) {
    .about-top-text {
        font-size: 22px;
    }

    .about-top {
        font-size: 14px;
    }
}

.about-top {
    /* font-size: 18px; */
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.about-top-card {
    border-color: white;
}

.vision {
    height: 60px;
    width: 60px;
}

.mission {
    height: 60px;
    width: 60px;
}

.visiontext {
    font-size: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.visiontext::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 40%;
    height: 4px;
    background-color: #4caf50;
}

.vision-col {
    color: #003FBD;
}

.visioncard {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.missiontext {
    font-size: 30px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.missiontext::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 40%;
    height: 4px;
    background-color: #4caf50;
}

.mission-col {
    color: #003FBD;
}

.missioncard {
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.corevalue {
    background-image: url('./corevalue.jpg');
    /* Replace with the actual path to your image */
    background-size: cover;
    /* Ensure the image covers the entire section */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 0;
}

.corevaluehead {
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    position: relative;
    display: inline-block;
}

.corevaluehead::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 42%;
    height: 4px;
    background-color: #4caf50;
}

.innovation {
    color: #62FF6A;
    font-size: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.innovationtext {
    color: white;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.quality {
    color: #62FF6A;
    font-size: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.qualitytext {
    color: white;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.integrity {
    color: #62FF6A;
    font-size: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.integritytext {
    color: white;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.customerfocus {
    color: #62FF6A;
    font-size: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.customerfocustext {
    color: white;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.collaboration {
    color: #62FF6A;
    font-size: 22px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.collaborationtext {
    color: white;
    font-size: 14px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ourteam {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.ourteam::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.team {
    color: #003FBD;
}

.whyus {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.whyus::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 30%;
    height: 4px;
    background-color: #4caf50;
}

.chooseus {
    color: #345095;
}

.experiencedteammaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.experiencedteamcard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.experiencedteam {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.experiencedteamtext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.customsolnmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.customsolncard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.customsoln {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.customsolntext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.innoappmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.innoappcard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.innoapp {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.innoapptext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.qlyassmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.qlyasscard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.qlyass {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.qlyasstext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.endsermaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.endsercard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.endser {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.endsertext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ccmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.cccard {
    background-color: #003FBD;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
}

.cc {
    font-size: 18px;
    color: white;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.cctext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* ---------------------footer----------------------- */

.footer-navi-btn {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-btn:hover {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-link {
    text-decoration: none;
}