.navibar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.companylogo {
    height: 70px;
    width: 80px;
}

.homebtn {
    background-color: white;
    border-color: white;
}

.homebtn:hover {
    background-color: white;
    border-color: white;
}


.aboutbtn {
    background-color: white;
    border-color: white;
}

.aboutbtn:hover {
    background-color: white;
    border-color: white;
}

.servicebtn {
    background-color: white;
    border-color: white;
}

.servicebtn:hover {
    background-color: white;
    border-color: white;
}

.workbtn {
    background-color: white;
    border-color: white;
}

.workbtn:hover {
    background-color: white;
    border-color: white;
}

.contactbtn {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.contactbtn:hover {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.hometext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.hometex2t:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.servicetext2:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.worktext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.contacttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

.contacttext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

/* -------------------- navigation bar complete -------------------- */

.service-pic {
    background-image: url('./Rectangle 10-1.png');
    /* Replace with the actual path to your image */
    background-size: cover;
    /* Ensure the image covers the entire section */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 0;
    padding: 16.8%;
}

.services {
    font-size: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
}

.appdev {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.appdev::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.appdevtext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.appdevtext2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.ps {
    color: #001EA4;
}

.appdevmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.appdevimage {
    border-radius: 12px;
}

.appdevcard {
    border-radius: 12px;
}

.web {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.web::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.webtext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.webtext2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.type {
    color: #001EA4;
}

.webmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.webimage {
    border-radius: 12px;
}

.webcard {
    border-radius: 12px;
}

.webapp {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.webapp::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.webapptext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.webapptext2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.type {
    color: #001EA4;
}

.webappmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.webappimage {
    border-radius: 12px;
}

.webappcard {
    border-radius: 12px;
}

.soft {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.soft::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.softtext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.softtext2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.typee {
    color: #001EA4;
}

.softmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.softimage {
    border-radius: 12px;
}

.softcard {
    border-radius: 12px;
}

.uiux {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    display: inline-block;
}

.uiux::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 4px;
    background-color: #4caf50;
}

.uiuxtext {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.uiuxtext2 {
    font-size: 16px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.process {
    color: #001EA4;
}

.uiuxmaincard {
    border-radius: 12px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.uiuximage {
    border-radius: 12px;
}

.uiuxcard {
    border-radius: 12px;
}

/* ---------------------footer----------------------- */

.footer-navi-btn {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-btn:hover {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-link {
    text-decoration: none;
}