.navibar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.companylogo {
    height: 70px;
    width: 80px;
}

.homebtn {
    background-color: white;
    border-color: white;
}

.homebtn:hover {
    background-color: white;
    border-color: white;
}


.aboutbtn {
    background-color: white;
    border-color: white;
}

.aboutbtn:hover {
    background-color: white;
    border-color: white;
}

.servicebtn {
    background-color: white;
    border-color: white;
}

.servicebtn:hover {
    background-color: white;
    border-color: white;
}

.workbtn {
    background-color: white;
    border-color: white;
}

.workbtn:hover {
    background-color: white;
    border-color: white;
}

.contactbtn {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.contactbtn:hover {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.hometext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.hometex2t:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext3:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.worktext1 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.worktext1:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: navy;
}

.contacttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

.contacttext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

/* -------------------- navigation bar complete -------------------- */

.work-pic {
    background-image: url('./Rectangle 10-2.png');
    /* Replace with the actual path to your image */
    background-size: cover;
    /* Ensure the image covers the entire section */
    background-position: center;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    border-radius: 0;
    padding: 16.8%;
}

.work {
    font-size: 48px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
}

.card-hover {
    border: none;
}

.nametitle {
    font-size: 26px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.nametitle2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* ---------------------footer----------------------- */

.footer-navi-btn {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-btn:hover {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-link {
    text-decoration: none;
}

/* ------------------Brands logo slider------------------ */

.slider {
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider-track {
    display: flex;
    animation: slide 10s linear infinite;
}

.slider-item {
    flex: 0 0 auto;
    width: 100px;
    /* Base width */
    margin: 0 8em;
}

.imagesize1 {
    height: 12em;
    width: 12.5em;
}

.imagesize2 {
    height: 12em;
    width: 20em;
}

.imagesize3 {
    height: 12em;
    width: 20em;
}

.imagesize4 {
    height: 12em;
    width: 12.5em;
}

@keyframes slide {
    0% {
        transform: translateX(95%);
    }

    100% {
        transform: translateX(-100%);
    }
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
    .slider-item {
        width: 80px;
    }
}

@media (max-width: 768px) {
    .slider-item {
        width: 60px;
    }
}

@media (max-width: 480px) {
    .slider-item {
        width: 50px;
        margin: 0 7em;
    }

    .imagesize1 {
        height: 10em;
        width: 10em;
    }

    .imagesize2 {
        height: 10em;
        width: 18em;
    }

    .imagesize3 {
        height: 10em;
        width: 20em;
    }

    .imagesize4 {
        height: 10em;
        width: 12.5em;
    }


    @keyframes slide {
        0% {
            transform: translateX(30%);
        }

        100% {
            transform: translateX(-100%);
        }
    }
}