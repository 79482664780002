.navibar {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
}

.companylogo {
    height: 70px;
    width: 80px;
}

.homebtn {
    background-color: white;
    border-color: white;
}

.homebtn:hover {
    background-color: white;
    border-color: white;
}


.aboutbtn {
    background-color: white;
    border-color: white;
}

.aboutbtn:hover {
    background-color: white;
    border-color: white;
}

.servicebtn {
    background-color: white;
    border-color: white;
}

.servicebtn:hover {
    background-color: white;
    border-color: white;
}

.workbtn {
    background-color: white;
    border-color: white;
}

.workbtn:hover {
    background-color: white;
    border-color: white;
}

.contactbtn {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.contactbtn:hover {
    background-color: navy;
    border-color: navy;
    border-radius: 8px;
}

.hometext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.hometex2t:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.abouttext3:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext3 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.servicetext3:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.worktext2 {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.worktext2:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
}

.contacttext {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

.contacttext:hover {
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 18px;
    color: white;
}

/* -------------------- navigation bar complete -------------------- */

.contactright {
    font-size: 36px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contactbtn {
    background-color: #003FBD;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contactbtn:hover {
    background-color: #003FBD;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contactleft {
    height: 100%;
    background-color: #F0EDFF;
    border-radius: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.contactrightcard {
    border-radius: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media (max-width: 480px) {
    .contactleft {
        display: none;
    }
}

.contactbtn1 {
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contactbtn2 {
    font-size: 18px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.contactbottomcard {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* ---------------------footer----------------------- */

.footer-navi-btn {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-btn:hover {
    background-color: white;
    border-color: white;
    color: black;
}

.footer-navi-link {
    text-decoration: none;
}